import { create } from 'zustand';

interface GlobalStore {
    isTransactionPinModalOpen: boolean;
    setTransactionPinModalOpen: (isOpen: boolean) => void;
    setPromptTransactionPinCallback: (callback: ((pin: string) => Promise<void>) | null) => void;
    promptTransactionPinCallback: ((pin: string) => Promise<void>) | null;
}

const useGlobalStore = create<GlobalStore>((set) => ({
    isTransactionPinModalOpen: false,
    setTransactionPinModalOpen: (isOpen) => set({ isTransactionPinModalOpen: isOpen }),
    setPromptTransactionPinCallback: (callback) => set({ promptTransactionPinCallback: callback }),
    promptTransactionPinCallback: null,
}));

export default useGlobalStore;
