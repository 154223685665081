"use client";

import React, { useEffect, useState } from "react";
import { MdDashboard, MdOutlineRocket } from "react-icons/md";
import { PiUsersThreeFill } from "react-icons/pi";
import { RiPieChart2Fill } from "react-icons/ri";
import { RiBarChartBoxFill } from "react-icons/ri";
import { BiSolidCart, BiSupport } from "react-icons/bi";
import { FaGlobeAmericas, FaShoppingBag } from "react-icons/fa";
import { FaFileInvoiceDollar, FaHandHoldingDollar } from "react-icons/fa6";
import { RiSettings4Fill } from "react-icons/ri";
import { IoDocumentText, IoRocket } from "react-icons/io5";
import { IoLogOutOutline } from "react-icons/io5";
import { RxChevronLeft, RxChevronRight } from "react-icons/rx";
import { RxExternalLink } from "react-icons/rx";
import Link from "next/link";
import { useAppStore } from "@/store";
import { Button } from "./ui/button";
import { GiPadlock } from "react-icons/gi";
import ComingSoon from "./comingSoon";
import ComingSoonGP from "./comingSoonGP";
import { usePathname, useRouter } from "next/navigation";
import { NotebookPen } from "lucide-react";
import { axiosInstance, swrFetcher } from "@/lib/constants";
import useSWR from "swr";
import { KYCModal } from "./KYCModal";
import { getCredentials, getNewAccessToken } from "@/lib/utils";
import EaziCollect from "./EaziCollect";
import { useTheme } from "next-themes";


const Sidebar = () => {
  const [showEsrowDetails, setShowEsrowDetails] = useState(false);
  const [showGPDetails, setShowGPDetails] = useState(false);
  const [showEaziCollectStatus, setShowEaziCollectStatus] = useState(false);

  const [
    mobileNav,
    toggleMobileNav,
    sidebarNav,
    toggleSidebarNav,
    sidebarSubmenu,
    setSidebarSubmenu,
    activeMenu,
    setActiveMenu,
  ] = useAppStore((state) => [
    state.mobileNav,
    state.toggleMobileNav,
    state.sidebarNav,
    state.toggleSidebarNav,
    state.sidebarSubmenu,
    state.setSidebarSubmenu,
    state.activeMenu,
    state.setActiveMenu,
  ]);

  // onClick={() => toggleMobileNav(!mobileNav)}
  const pathname = usePathname();

  const isActive = (route) => {
    return pathname.startsWith(route);
  };

  const [tier, setTier] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  const { resolvedTheme } = useTheme();

  const {
    data: kycData,
    isLoading: kycLoading,
    error: kycError,
  } = useSWR("/kyc/details", swrFetcher);

  const getUserTier = () => {
    const user = getCredentials();
    setTier(user?.tier);
  };

  useEffect(() => {
    getUserTier();
    setIsMounted(true);
  }, []);

  //#020817
  return (
    <aside
      className={`${sidebarNav ? " w-60  " : "w-14 "
        } overflow-hidden flex flex-col  left-0 top-[60px] h-[calc(100dvh-60px)]    duration-500 font-codecpro   border-r-2 pt-6 pb-0 px-2   z-10 fixed  bg-primary_blue
      ${!isMounted && "hidden"}
       ${resolvedTheme !== "light" ? "!bg-[#262A46]" : "!bg-primary_blue"}  `}
    >
      {kycData?.data?.status?.toLocaleString() === "pending" && (
        <KYCModal
          kycData={kycData}
          kycError={kycError}
          kycLoading={kycLoading}
          tier={tier}
        />
      )}
      <section className="w-full flex flex-col flex-1">
        <nav className="space-y-2 text-white">
          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/dashboard"
            className={`${isActive("/dashboard") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <div className="flex items-center gap-3 ">
              <RiPieChart2Fill
                size={20}
                className="text-inherit group-hover:text-white"
              />
              <span className={`${sidebarNav ? "opacity-100" : "opacity-0"} duration-500 text-base`}>
                Dashboard
              </span>
            </div>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>
          <button
            href="/wallet"
            className={`${isActive("/wallet") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <div className="flex text-inherit items-center gap-3 py-1">
              <FaGlobeAmericas size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100 " : "opacity-0 "
                  }  text-base overflow-hidden text-nowrap duration-500`}
              >
                Wallet
              </span>
            </div>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </button>
          {/* /////// */}
          <button
            onClick={() => {
              setShowEaziCollectStatus((prev) => !prev);
              toggleSidebarNav(false);
            }}
            className={`${isActive("/eazi-collect") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            {showEaziCollectStatus && (
              <EaziCollect
                setShowEaziCollectStatus={setShowEaziCollectStatus}
              />
            )}
            <div className="flex text-inherit items-center gap-3 py-1">
              <FaHandHoldingDollar
                size={20}
                className=" group-hover:text-white"
              />
              <span
                className={`${sidebarNav ? "opacity-100 " : "opacity-0 "
                  }  text-base overflow-hidden text-nowrap duration-500`}
              >
                Eazi Collect
              </span>
            </div>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </button>

          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/transactions"
            className={`${isActive("/transactions") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex text-inherit items-center gap-3">
              <RiBarChartBoxFill
                size={20}
                className=" group-hover:text-white"
              />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Transactions
              </span>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>

          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/customers"
            className={`${isActive("/customers") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex items-center text-inherit gap-3">
              <PiUsersThreeFill size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100 " : "opacity-0"
                  } duration-500 text-base`}
              >
                Customers
              </span>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>
          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/invoice"
            className={`${isActive("/invoice") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex items-center text-inherit gap-3">
              <FaFileInvoiceDollar
                size={20}
                className=" group-hover:text-white"
              />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Invoices
              </span>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>
          <Link
            href="/escrow"
            className={`${isActive("/escrow") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <div className="flex items-center text-inherit gap-3">
              <GiPadlock size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Escrow
              </span>
            </div>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>
          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/payment-link"
            className={`${isActive("/payment-link") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex items-center text-inherit h-8 gap-3">
              <MdOutlineRocket
                size={20}
                className={`${sidebarNav ? "" : " "
                  } group-hover:text-white min-w-5  `}
              />

              <div
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base w-28   text-clip`}
              >
                Payment Link
              </div>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white min-w-5"
            />
          </Link>
          <Link
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="/account"
            className={`${isActive("/account") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex items-center text-inherit gap-3">
              <RiSettings4Fill size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Account
              </span>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              toggleSidebarNav(false);
            }}
            href="https://docs.centiiv.com"
            className={`${isActive("/docs") && "text-white"} flex items-center justify-between px-2 py-2 hover:text-opacity-100 group text-white text-opacity-80`}
          >
            <section className="flex items-center text-inherit gap-3">
              <NotebookPen size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Docs
              </span>
            </section>
            <RxChevronRight
              size={20}
              className="text-inherit group-hover:text-white"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://centiiv.zohodesk.com/portal/en/home"
            className="flex items-center justify-between px-2 py-2 hover:text-white group"
          >
            <section className="flex items-center gap-3">
              <BiSupport size={20} className=" group-hover:text-white" />
              <span
                className={`${sidebarNav ? "opacity-100" : "opacity-0"
                  } duration-500 text-base`}
              >
                Support
              </span>
            </section>
            <RxChevronRight size={20} className=" group-hover:text-white" />
          </a>
        </nav>
      </section>

      <section className="w-full block border-t-2 border-white p-2 ">
        <Button
          variant="ghost"
          className="text-white cursor-pointer hover:bg-transparent hover:text-white p-0 duration-300"
          onClick={() => {
            toggleSidebarNav(!sidebarNav);
          }}
        >
          {" "}
          {sidebarNav}
          <RxChevronLeft
            size={30}
            className={`duration-500 ${sidebarNav ? "rotate-0" : "rotate-180"}`}
          />
        </Button>
      </section>
    </aside>
  );
};

// const Sidebar2 = () => {
//   const router = useRouter();
//   const [collapsed, setCollapsed] = useState(false);
//   const { token } = theme.useToken();

//   type MenuItem = Required<MenuProps>['items'][number];

//   function getItem(
//     label: React.ReactNode,
//     key: string,
//     icon?: React.ReactNode,
//     isExternal?: boolean
//   ): MenuItem {
//     return {
//       key,
//       icon,
//       label,
//       onMouseEnter: () => {
//         router.prefetch(key);
//       },
//       onClick: () => {
//         if (isExternal) {
//           window.open(key, '_blank');
//         } else {
//           router.push(key);
//         }
//       }
//     } as MenuItem;
//   }


//   const items: MenuItem[] = [
//     getItem('Dashboard', '/dashboard', <RiPieChart2Fill />),
//     getItem('Global Payment', '/global-payment', <FaGlobeAmericas />),
//     getItem('Eazi Collect', '/eazi-collect', <FaHandHoldingDollar />),
//     getItem('Transactions', '/transactions', <RiBarChartBoxFill />),
//     getItem('Customers', '/customers', <PiUsersThreeFill />),
//     getItem('Invoices', '/invoice', <FaFileInvoiceDollar />),
//     getItem('Escrow', '/escrow', <GiPadlock />),
//     getItem('Payment Link', '/payment-link', <MdOutlineRocket />),
//     getItem('Account', '/account', <RiSettings4Fill />),
//     getItem('Docs', 'https://docs.centiiv.com', <NotebookPen />, true),
//     getItem('Support', 'https://centiiv.zohodesk.com/portal/en/home', <BiSupport />, true),
//   ];

//   return (
//     <Layout.Sider 
//       width={200} 
//       style={{ background: token.colorPrimary }}
//       collapsible 
//       collapsed={collapsed}
//       onCollapse={(value) => setCollapsed(value)}
//     >
//       <Menu
//         mode="inline"
//         defaultSelectedKeys={['dashboard']}
//         style={{ height: '100%', borderRight: 0 }}
//         items={items}
//         className="!bg-primary_blue sidebar"
//       />
//     </Layout.Sider>
//   );
// }

export default Sidebar;
