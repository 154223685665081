"use client"
import React from 'react';
import Sidebar from '@/components/Sidebar';
import Header from '@/components/Header';
import { TransactionPinModal } from '@/components/TransactionPinModal';
import { usePathname } from 'next/navigation';


export const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const route = usePathname();
  const showNewLayout = route == "/account" || route == "/wallet" || route == "/transactions";

  if (!showNewLayout)
    return children;

  return (
    <section className="bg-background text-foreground min-h-screen">
      <Header />
      <section className="flex w-full pt-24">
        <Sidebar />
        <div className="pl-20 pr-6 max-w-screen-lg w-full mx-auto">
          {children}
        </div>
      </section>

      <TransactionPinModal />
    </section>
  );
};