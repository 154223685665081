"use client";

import { FaTimes } from "react-icons/fa";
import React, { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/ui/Loader";

import AuthErrorUI from "@/components/AuthErrorUI";
import Link from "next/link";
import { replaceIdInUrl } from "@/lib/utils";
export function KYCModal({ kycData, kycError, kycLoading, tier }) {
  const [formID, setFormID] = useState(null);
  const handleChange = (value) => {
    setFormID(value);
  };

  return (
    <div className="fixed text-foreground   backdrop-blur-sm bg-black/20 z-[10] inset-0 flex justify-center items-center text-xs sm:text-sm">
      <div className="bg-background  border-border border rounded-xl max-w-80 sm:max-w-[450px] w-full  p-8 mb-2 relative">
        <p className="mt-4 text-center font-semibold ">KYC DETAILS</p>
        {kycData && (
          <div className="flex flex-col items-center justify-center mt-6  w-full mx-auto border p-6 gap-4 rounded-xl">
            <div className="flex  w-full flex-col gap-4 justify-start ">
              <div className=" w-full flex gap-2 ">
                <span className="text-gray-400 ">KYC Status</span>
                <span className=" ">:</span>
                <span className="">
                  {kycData.data?.status?.toLocaleString() === "pending" && (
                    <span className="text-yellow-600 uppercase">
                      {kycData.data?.status}
                    </span>
                  )}
                  {kycData.data?.status?.toLocaleString() === "unverified" && (
                    <span className="text-red-600 uppercase">
                      {kycData.data?.status}
                    </span>
                  )}
                  {kycData.data?.status?.toLocaleString() === "verified" && (
                    <span className="text-green-600 uppercase">
                      {kycData.data?.status}
                    </span>
                  )}
                </span>
              </div>
              <div className=" w-full flex gap-2 ">
                <span className="text-gray-400 ">Account Tier </span>
                <span className=" ">:</span>
                <span className="">
                  {tier && <span className="uppercase">{tier}</span>}
                </span>
              </div>
            </div>

            <div className="w-full">
              <Select className="w-full" onValueChange={handleChange}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select business type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Business</SelectLabel>
                    <SelectItem value="wbOEQ7">
                      Incorporated Business
                    </SelectItem>
                    <SelectItem value="3jL2yY">Business Name</SelectItem>
                    <SelectItem value="3N0kGb">
                      Unregistered Business
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="  flex flex-col sm2:flex-row gap-6 sm2:justify-start w-full ">
              {formID ? (
                <>
                  <Link
                    href={replaceIdInUrl(formID, kycData.data?.kycFormLink)}
                    className="px-6 py-2 rounded-md border text-center w-full"
                  >
                    View KYC Form
                  </Link>
                  <Link
                    href={replaceIdInUrl(formID, kycData.data?.kycFormLink)}
                    className="px-6 py-2 rounded-md border text-center w-full"
                  >
                    Update KYC
                  </Link>
                </>
              ) : (
                <>
                  <button
                    disabled
                    className="px-6 py-2 rounded-md border text-center w-full"
                  >
                    View KYC Form
                  </button>
                  <button
                    disabled
                    className="px-6 py-2 rounded-md border text-center w-full"
                  >
                    Update KYC
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <div className=" w-fit mx-auto mt-5 ">
          {kycLoading && !kycData && (
            <div className=" ">
              <Loader />
            </div>
          )}

          {kycError && !kycLoading && !kycData && (
            <AuthErrorUI
              status={
                kycError.code === "ERR_NETWORK"
                  ? 408
                  : kycError.response?.status
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
