import { create } from "zustand";
 type Profile  = { email: string,
    businessName: string,
    firstName: string,
    lastName: string} 

  //  const userInfo = getCredentials()

interface AppState {
  mobileNav: boolean;
  toggleMobileNav: (open: boolean) => void;
  sidebarNav: boolean;
  toggleSidebarNav: (open: boolean) => void;
  sidebarSubmenu: string | null;
  setSidebarSubmenu: (submenu: string | null) => void;
  activeMenu: string | null;
  setActiveMenu: (activemenu: string | null) => void;
  // userProfile : Profile | null ;
  // updateProfile: (profile: Profile) => void;

}

export const useAppStore = create<AppState>((set) => ({
  mobileNav: false,
  toggleMobileNav: (open) => set((state) => ({ mobileNav: open })),
  sidebarNav: false,
  toggleSidebarNav: (open) => set((state) => ({ sidebarNav: open })),
  sidebarSubmenu: null,
  setSidebarSubmenu: (submenu) => set({ sidebarSubmenu: submenu }),
  activeMenu: null,
  setActiveMenu: (activemenu) => set({ activeMenu: activemenu }),
  // userProfile : userInfo,
  // updateProfile : (profile) => set({ userProfile: profile })
}));
