import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import escrow from "../public/escrow.png";
import Image from "next/image";

function ComingSoon({ setShowEsrowDetails }) {
  return (
    <div
      onClick={() => setShowEsrowDetails((prev) => !prev)}
      className="fixed bg-black/70 inset-0 text-black flex items-center h-full cursor-default "
    >
      <div
        className="border md:w-1/2 relative   bg-white rounded-xl p-6 mx-6 md:mx-auto
       "
      >
        <p
          onClick={() => setShowEsrowDetails((prev) => !prev)}
          className="cursor-pointer absolute h-12 w-12 rounded-full border -top-5 text-white bg-primary_blue flex justify-center items-center -right-5"
        >
          <AiOutlineClose size={25} />
        </p>
        <div className="flex justify-center items-center flex-col gap-4">
          <p className="font-semibold text-lg">Coming Soon</p>
          {/* <Image
            src={escrow}
            alt="escrow"
            width={25}
            height={25}
            className="w-40 h-60  "
            priority
          /> */}
          <p className="  ">
            Build trust with your customers by leveraging the in-built escrow
            system.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
