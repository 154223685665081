import axios from "axios";

// Base URL configuration based on environment variables
const getBaseUrl = () => {
  if (process.env.NEXT_PUBLIC_LOCAL) {
    return "http://localhost:3002";
  }
  
  switch (process.env.NEXT_PUBLIC_NODE_ENV) {
    case "development":
      return "https://api.dev.centiiv.com";
    case "production":
      return "https://api.prod.centiiv.com";
    default:
      return "https://api.preprod.centiiv.com";
  }
};

export const BASE_URL = getBaseUrl();

// Axios instance configuration
export const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": BASE_URL,
    "Origin-Allow-Credentials": true,
  },
});

// SWR fetcher for data retrieval
export const swrFetcher = (url) => 
  axiosInstance.get(url).then((res) => res.data);

// Initialize Axios with authorization token
export const initializeAxios = () => {
  if (typeof window !== "undefined" && 'localStorage' in window) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }
}

// Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// SWR fetcher for payment data retrieval
export const swrPaymentFetcher = (url) => 
  axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": BASE_URL,
      "Origin-Allow-Credentials": true,
    },
  }).then((res) => res.data);
