import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/codebuild/output/src2171952284/src/frontend/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardLayout"] */ "/codebuild/output/src2171952284/src/frontend/app/DashboardLayout.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2171952284/src/frontend/components/AuthorizationProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src2171952284/src/frontend/components/themeProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/codebuild/output/src2171952284/src/frontend/context/AuthContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2171952284/src/frontend/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2171952284/src/frontend/node_modules/sonner/dist/index.mjs");
