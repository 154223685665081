import React from "react";
import Loader from "./ui/Loader";

function AuthErrorUI({ status }) {
  return (
    <div className="w-fit mx-auto mt-10">
      {status === 401 && (
        <p className="text-lg font-semibold uppercase"> Auth is required</p>
      )}
      {status === 408 && (
        <p className="text-lg font-semibold uppercase">Network error</p>
      )}
      {status === 403 && (
        <div>
          <p className="flex gap-3">
            <span className="text-lg font-semibold uppercase">
              Verifying Auth Status
            </span>
            <Loader />
          </p>
          <p className="mt-4">This may take a moment ... </p>
        </div>
      )}

      <div></div>
    </div>
  );
}

export default AuthErrorUI;
