"use client"
import useGlobalStore from "@/context/store/useGlobalStore";
import { Input, Modal } from "antd";
import { useState } from "react";

export const TransactionPinModal = () => {
    const { isTransactionPinModalOpen,
        setTransactionPinModalOpen,
        promptTransactionPinCallback,
        setPromptTransactionPinCallback
    } = useGlobalStore();

    const [pin, setPin] = useState("");


    return <>
        <Modal
            title="Enter Transaction PIN"
            zIndex={100}
            open={isTransactionPinModalOpen}
            onOk={async() => {
                if (promptTransactionPinCallback) {
                    await promptTransactionPinCallback(pin);
                }
                setTransactionPinModalOpen(false);
            }}
            onCancel={() => {
                setPromptTransactionPinCallback(null);
                setTransactionPinModalOpen(false);
            }}
            okButtonProps={{ disabled: pin.length !== 4 }}
        >
            <div className="flex justify-center my-8">
                <Input.OTP
                    value={pin}
                    onChange={(value) => setPin(value)}
                    length={4}
                    type="password"
                />
            </div>
        </Modal>
    </>
}